const contact = {
  title: "Kontaktieren Sie mich über Touren durch Schottland",
  description:
  "Für Anfragen und weitere Informationen darüber, welche Touren ich anbieten kann, kontaktieren Sie mich bitte und ich werde mich bei Ihnen melden. Ich freue mich darauf, von dir zu hören!",
  h1: "Kontaktiere mich",
  para1:
    "Für Anfragen und weitere Informationen darüber, welche Touren ich anbieten kann, füllen Sie bitte das unten stehende Formular aus und ich werde mich wieder mit Ihnen in Verbindung setzen.",
  para2: "Ich freue mich darauf, von dir zu hören!",  
  sheepAltText: "Schottische Hochlandschafe, die Gras am Straßenrand essen.",
};

export default contact;
