const contact = {
  title: "Contact me about tours of Scotland",
  description:
  "For enquires and more information on what tours I can provide please contact me and i shall get back to you. I look forward to hearing from you!",
  h1: "Contact Me",
  para1:
    "For enquires and more information on what tours I can provide please complete the below for and i shall get back in contact.",
  para2: "I look forward to hearing from you!",
  sheepAltText: "Scottish highland sheep eating grass at the side of the road.",
};

export default contact;
