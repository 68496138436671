const highlands = {
  title: "Touren durch die Highlands &amp; Inseln",
  description:
  "Das schottische Hochland und die schottischen Inseln erstrecken sich über ein weites, dünn besiedeltes Gebiet mit vielfältigen Landschaften, von mit Heidekraut bedeckten Bergen und einsamen Mooren bis hin zu Kiefernwäldern und romantischen Seen (lochs) und Tälern (glens).",
  h1: "Tour durch die Highlands & Islands",
  para1:
    "Die Highlands und schottischen Inseln umfassen ein riesiges, sehr dünn besiedeltes Gebiet mit einer vielfältigen Landschaft. Mit Heidekraut bedeckte Berge, einsame Hochlandmoore bis hin zu den Kiefernwäldern und romantischen Seen (lochs) und Tälern (glens).",
  para2:
    "Besuchen Sie mystische Stätten und bekannte Drehorte. Reisen Sie mit dem Dampfer Sir Walter Scott auf Loch Katrine, zurück in die Vergangenheit. Fahren Sie mit der {link} über den „Harry Potter Viadukt“ zwischen Fort William und Mallaig, wo die Seehunde im Hafen spielen, bevor Sie mit der {link} bis Skye, auf die „Insel des Nebels“ übersetzen.",
  links: [
    { text: "„Jacobite“ Dampfeisenbahn", title: "Das „Jacobite“ Dampfeisenbahn website" },
    { text: "Fähre", title: "Die Calmac Ferry Websitee" },
  ],
  para3:
    "Erkunden Sie die Hebrideninseln mit ihren kristallklaren Küstenwässern und perfekten Sandstränden. Besuchen Sie vielleicht die winzige Insel Iona, Wiege des schottischen Christentums oder machen Sie einen Abstecher auf Islay, die Whiskyinsel, wo neun Destillerien „uisge beatha“ produzieren. Probieren Sie, bevor Sie eine Flasche Single Malt auswählen oder kaufen Sie als Andenken ein hochwertiges Souvenir bei einem lokalen Kunstgewerbler ein.",
  para4: "Schottland hat noch viel mehr zu bieten!",
  reviews: [
    {
      text:
        "Vielen Dank, dass Sie sich so gut um die deutsche Gruppe gekümmert haben. Das Feedback der Gruppe war äußerst positiv - es war eine Erleichterung, einen guten Leitfaden zu haben, auf den man sich verlassen kann",
      by: "Deutsche Unternehmensgruppe",
    },
  ],
  CannonKirkAltText: "Kaninchen in einer Wandkanone Kirk Scotland"
};

export default highlands;
