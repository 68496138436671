const memberOf = {
  MemberOfText: "Mitglied von {link} das ist ein mitglied der {link}(WFTGA) und mitglied der {link}(FEG).",
  MemberOfLinkText1: "Scottish Tourist Guides Association",
  MemberOfLinkTitle1: "Marion Rodger Scottish Tourist Guides Association profil.",
  MemberOfLinkText2: "World Federation of Tourist Guide Associations",
  MemberOfLinkTitle2: "Das World Federation of Tourist Guide Associations webseite.",
  MemberOfLinkText3: "European Federation of Tourist Guide Associations",
  MemberOfLinkTitle3: "Das European Federation of Tourist Guide Associations webseite.",
  GoodToGoAltText: "Good to go Scotland logo",
  STGAAltText: "Scottish Tourist Guides Association logo",
  WFTGAAltText: "World Federation of Tourist Guide Associations logo",
  FEGAltText: "European Federation of Tourist Guide Associations logo",
};

export default memberOf;
