const highlands = {
  title: "Tours of Highlands &amp; Islands",
  description:
  "The Scottish Highlands and Islands cover a vast, sparsely populated area with diverse landscapes from heather covered mountains and lonely moors to pine forests and romantic lochs and glens.",
  h1: "Tour the Highlands & Islands",
  para1:
    "The Scottish Highlands and Islands cover a vast, sparsely populated area with diverse landscapes from heather covered mountains and lonely moors to pine forests and romantic lochs and glens.",
  para2:
    "Visit mystical sites and recent blockbuster film locations then step back in time with a steamer trip on Loch Katrine or take the {link} over the “Harry Potter viaduct” from Fort William to Mallaig to watch the seals frolicking in the harbour before catching the {link} to Skye, “the misty isle”.",
  links: [
    { text: "Jacobite steam train", title: "The Jacobite steam train website" },
    { text: "Calmac ferry", title: "The Calmac ferry website" },
  ],
  para3:
    "Explore the Hebrides with crystal clear coastal waters and perfect sandy beaches and visit the tiny island of Iona, the cradle of Scottish Christianity or Islay, the whisky island where 9 distilleries still produce “uisge beatha”. Try before you buy a bottle of Single Malt or take home a quality souvenir made locally by talented craftsmen.",
  para4: "And so much more ....!",
  reviews: [
    {
      text:
        "Thanks for taking such good care of the German group. The feedback from the group has been extremely positive - it was a relief to have a good guide to rely on",
      by: "German Business Group",
    },
  ],
  CannonKirkAltText: "Rabbit in a wall cannon Kirk Scotland"
};

export default highlands;
