const about = {
    title: "Über meine Reiseleitung",
    description:
      "Als erfahrenes Mitglied der Scottish Tourist Guides Association bin ich ein voll qualifizierter, freiberuflicher Reiseleiter für Schottland.",
    h1: "Über mich",
    para1: "Obgleich ich “südlich der Grenze” geboren bin, wohne ich seit 30 Jahren in Schottland und bin eine begeisterte Botschafterin für mein adoptiertes Land. Ich reise immer noch gerne in Schottland – ob mit einer Reisegruppe oder mit dem PKW, auf einem Familienurlaub mit dem Rad über die Insel oder beim Lauf eines Halbmarathons, gegen das stürmische Wetter.",
    para2: "Als erfahrenes Mitglied des Scottish Tourist Guides Association, bin ich eine voll qualifizierte, selbstständige Reiseleiterin für ganz Schottland.",
    h2: "Qualifikationen und Erfahrung",
    li1: "Heriot-Watt Universität - BA (Ehrungen) in Französisch & Deutsch (Übersetzen und Dolmetschen)",
    li2: "University of Dundee / Open University - Moderne schottische Geschichte von 1707",
    li3: "Scottish Agricultural College - HND Gartengestaltung",
    li4: "RHS Zertifikat im Gartenbau",
    li5: "Erste Hilfe bei der Arbeit",
    li7: "STGA Akkreditierung für Touren in der {link}",
    li7linkText: "schottischen Parlament",
    li7linkTitle: "Die Website des schottischen Parlaments.",
    li8: "STGA Akkreditierung für {link} Sportliche Erbe-Touren (Bus & zu Fuß)",
    li8linkText: "G:2014",
    li8linkTitle: "Glasgow Commonwealth Games 2014",
    li9: "Volunteer Education Guide mit dem ",
    li9linkText: "National Trust for Scotland",
    li9linkTitle: "The National Trust for Scotland webseite.",
    li10: "Zug host Belmond Royal Scotsman",
    li10linkText: "Belmond Royal Scotsman",
    li10linkTitle: "Das Belmond Royal Scotsman train journey webseite.",
    PrestonMillAltText: "Edinburgh & das Lothians, Preston Mill.",
};
  
  export default about;