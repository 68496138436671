const glasgow = {
  title: "Touren durch Glasgow",
  description:
  "Die Stadt verfügt über ein reiches industrielles Erbe, sowie eine faszinierende Mischung von traditioneller und moderner Architektur, eine lebhafte Musik- und Kulturszene, hervorragende Museen, Galerien und Top Einkaufsmöglichkeiten.",
  h1: "Touren durch Glasgow",
  para1:
    "Glasgow, ehemals Kulturhauptstadt Europas, britische Stadt für Architektur und Design und Gastgeber der Commonwealth Games 2014.",
  para2:
    "Die Stadt verfügt über ein reiches industrielles Erbe, sowie eine faszinierende Mischung von traditioneller und moderner Architektur, eine lebhafte Musik- und Kulturszene, hervorragende Museen, Galerien und Top Einkaufsmöglichkeiten.",
  para3:
    "Entdecken Sie die {link} oder besuchen Sie die “Merchant City” mit ihrer imposanten viktorianischen Architektur und modischen Restaurants. Machen Sie einen Spaziergang auf Glasgow Green oder begeben Sie sich auf die Spuren des Architekts, Charles Rennie Mackintosh. Das prachtvolle {link} oder das {link} in dem sensationellen am Ufer des River Clyde sind ebenfalls einen Besuch wert. Schließlich können Sie bis zum Umfallen, in Glasgows stilvoller Buchanan Street, shoppen.",
  links: [
    { text: "mittelalterliche Kathedrale Glasgows", title: "Kathedrale Glasgows website" },
    {
      text: "Kelvingrove Kunstmuseum",
      title: "Kelvingrove Kunstmuseum website",
    },
    { text: "Verkehrsmuseum", title: "Verkehrsmuseum website" },
  ],
  laphroaigAltText: "Laphroaig Distillery tank",
};

export default glasgow;
