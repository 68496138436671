import {lazy} from "react";
//import Home from "../Pages/Home";
//import About from "../Pages/About";Edinburgh

const Home = lazy(() => import('../Pages/Home'));
const About = lazy(() => import('../Pages/About'));
const Contact = lazy(() => import('../Pages/Contact'));
const Services = lazy(() => import('../Pages/Services'));
const HighlandsAndIslands = lazy(() => import('../Pages/HighlandsAndIslands'));
const Edinburgh = lazy(() => import('../Pages/Edinburgh'));
const Glasgow = lazy(() => import('../Pages/Glasgow'));

const routes = [
  {
    key: "home",
    path: "/",
    Text: "Home",
    component: Home,
    images: [
      "images/falconry-display-harris-hawk",
      "images/ascog-fernery",
      "images/paps-of-jura"
    ],
  },
  {
    key: "about",
    path: "/about-our-scottish-tours",
    Text: "About",
    component: About,
    images: [
      "images/hebridean-cycle-tour",
      "images/lismore-lighthouse",      
      "images/forth-bridge"
    ],
  },
  {
    key: "services",
    path: "/scottish-tour-services",
    Text: "Services",
    images: [
      "images/tarbert-harbour",
      "images/crossraguel-abbey",      
      "images/cormorant-on-isle-of-lewis"
    ],
    component: Services,
  },
  {
    key: "escape",
    path: "/tour-the-scottish-highlands-and-islands",
    Text: "Escape",
    images: ["images/loch-katrine-boat"],
    component:HighlandsAndIslands,
    subRoutes: [
      {
        key: "highlands",
        path: "/tour-the-scottish-highlands-and-islands",
        Text: "Highlands & Islands",
        component:HighlandsAndIslands,
      },
      {
        key: "edinburgh",
        path: "/tour-edinburgh",
        Text: "Edinburgh",
        component: Edinburgh,
        images: ["images/edinburgh-castle"],
      },
      {
        key: "glasgow",
        path: "/tour-glasgow",
        Text: "Glasgow",
        component: Glasgow,
        images: ["images/doulton-fountain-glasgow"],
      }
    ],
  },
  {
    key: "contact",
    path: "/scottish-tour-contact-us",
    Text: "Contact",
    component: Contact,
    images: ["images/culzean-castle"],
  },
];

export default routes;
