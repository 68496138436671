const glasgow = {
  title: "Tours of Glasgow",
  description:
  "Glasgow has a rich industrial heritage, a fascinating mix of old and new architecture, a lively music and arts scene, outstanding museums and galleries and is a top retail destination.",
  h1: "Tour Glasgow",
  para1:
    "Glasgow, formerly European Capital of Culture, UK city of Architecture and Design and host of the 2014 Commonwealth Games.",
  para2:
    "Glasgow has a rich industrial heritage, a fascinating mix of old and new architecture, a lively music and arts scene, outstanding museums and galleries and is a top retail destination.",
  para3:
    "Discover {link}, the Merchant City with its grand Victorian architecture and fashionable restaurants, take a walk on Glasgow Green, follow the trail of architect Charles Rennie Mackintosh, visit the magnificent {link} or {link}, Glasgow’s award-winning Transport Museum in its stunning building on the River Clyde. Then shop till you drop in Glasgow’s stylish Buchanan Street.",
  links: [
    { text: "Glasgow’s mediaeval cathedral", title: "Glasgow Cathedral website" },
    {
      text: "Kelvingrove Museum and Art Gallery",
      title: "The Kelvingrove Museum and Art Gallery website",
    },
    { text: "the Riverside", title: "The Riverside Museum website" },
  ],
  laphroaigAltText: "Laphroaig Distillery tank",
};

export default glasgow;
