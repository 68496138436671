import React from "react";
import { Spinner, Box } from "@chakra-ui/react";

const LoadingComponent = ({ transparent }) => {
  return (
    <Box
      background={transparent ? "" : "rgba(155, 155, 155, 0.45)"}
      position="absolute"
      top="0px"
      bottom="0px"
      width="100%"
      minHeight="300px"
      display="flex"
      zIndex="1000"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      justifyItems="center"
    >
      <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
    </Box>
  );
};

export default LoadingComponent;
