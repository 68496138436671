const edinburgh = {
  title: "Touren durch Edinburgh",
  description:
  "Edinburgh, Scotland’s capital city and financial centre is a city for all seasons, world famous for its festivals, cultural heritage and incredible restaurants.",
  h1: "Touren durch Edinburgh",
  para1:
    "Edinburgh, Hauptstadt und Finanzzentrum Schottlands, ist für seine Festspiele weltbekannt und ist das ganze Jahr über eine Reise wert.",
  para2:
    "Die Altstadt, sowie die Neustadt von Edinburgh sind als Weltkulturerbe geschützt und beim Kennenlernen der vielen Besucherattraktionen, werden Sie in die Fußstapfen von berühmten literarischen Figuren treten – u.a. Sir Walter Scott, Robert Burns, Robert Louis Stevenson, Sir Arthur Conon Doyle, Ian Rankin oder JK Rowling.",
  para3:
    "Machen Sie einen Bummel die Royal Mile entlang der {link} bis zum {link}. Werfen Sie einen Blick in unser modernes {link}, sehen Sie sich die neueste Ausstellung in der Kunstgalerie an oder steigen Sie auf unseren erlöschenen Vulkan, Arthurs Seat, auf. Am Ende des Tages können Sie in einem erstklassigen Restaurant speisen oder sich in einem typischen Edinburgh Pub entspannen.",
  links: [
    { text: "Burg", title: "Edinburgh burg website" },
    {
      text: "Königsschloß Holyroodhouse",
      title: "Königsschloß Holyroodhouse website",
    },
    { text: "Schottisches Parlament", title: "Schottisches Parlament website" },
  ],
  reviews: [
    {
      text:
        "Liebe Marion, dank dir und Emma hatten wir wundervolle Tage in Schottland. Die Tour gab uns eine gute Vorstellung davon, was Ihr Land zu bieten hat.",
      by: "Deutsche Gruppe",
    },
  ],
  HolyroodhouseAltText: "Straßenlaterne im Königsschloß Holyroodhouse",
};

export default edinburgh;
